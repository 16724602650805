import './src/global.scss';
import './src/text.scss';
import './src/page-styles/page-styles.scss';
import './src/components/page-layout/page-layout.scss';
import './src/components/header/header.scss';
import './src/components/discography/discography.scss';
import './src/components/contact-form/contact-form.scss';
import './src/components/footer/footer.scss';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
